import React from 'react'
import styled, {css} from 'styled-components'
import Section from '../../../../resuable/section/section'
import Row from '../../../../resuable/row/row'
import { ButtonType } from '../../../../resuable/button'
import Popup from '../../../../resuable/popup'
import useDisclosure from '../../../../hooks/useDisclosure'

const StyledExplainerVideo = styled.div`
  padding: 80px 0px;
  .video {
    margin-bottom: 30px;
    .video-placeholder {
      height: 440px;
      width: 720px;
      background: #bbb;
    }
  }
  .main-video {
    border: 2px solid yellow;
  }
`

const ExplainerVideo = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Section fullwidth={true} padding="0px" background="#0f0f0f">
      <StyledExplainerVideo>
        <Row className="video">
          <div className="video-placeholder">
            this is a video
          </div>
        </Row>
        <Row className="heading">
          <h2>Marketing was never meant to be hard.</h2>
        </Row>
        <Row className="ctas">
          <ButtonType.Base onClick={onOpen}>
            Get Started Now
          </ButtonType.Base>
        </Row>
      </StyledExplainerVideo>
      <Popup
        isOpen={isOpen}
        onClose={onClose}
        className="video"
        style={`
          .main-video {
            border: 1px solid red;
          }
        `}
      >
        hello
      </Popup>
    </Section>
  )
}

ExplainerVideo.defaultProps = {

}

export default ExplainerVideo