import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { trimString } from "../../../../utils/helpers/trimmer"
import { placeholderImage } from "../../../../utils/helpers/generator"
import { colors } from "../../../../utils/styles/styles"

const StyledBlogCardImageBack = styled(Link)`
  box-sizing: border-box;
  position: relative;
  height: 300px;
  width: 100%;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  /* &:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 20px;
  } */
  div.container__image {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    height: 100%;
    width: 100%;
    img,
    .backimage {
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: brightness(55%);
      transition: 0.6s all;
    }
  }
  div.container__content {
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    padding: 30px 24px;
    hr {
      width: 100px;
      height: 4px;
      margin-bottom: 20px;
      background: ${colors.priDark};
    }
    .title {
      font-size: 20px;
      color: #fff;
    }
    .excerpt {
      p {
        font-size: 14px;
        color: ${colors.lightGray};
      }
    }
    button {
      cursor: pointer;
      background: transparent;
      color: ${colors.priDark};
      font-size: 14px;
      font-weight: 700;
    }
  }
  &:hover {
    div.container__image {
      img {
        transform: scale(1.1);
        transition: 0.6s all;
      }
    }
  }
  @media (max-width: 768px) {
    height: 360px;
    &:not(:last-child) {
      margin-right: 0px;
    }
    div.container__content {
      .title {
        font-size: 16px;
      }
      .excerpt {
        p {
          font-size: 12px;
          color: ${colors.lightGray};
        }
      }
    }
  }
`

const BlogCardImageBack = ({ title, excerpt, slug, featured_media }) => {
  return (
    <StyledBlogCardImageBack to={`/a2i-world/${slug}`} title={title}>
      <div className="container__image">
        {featured_media === undefined || featured_media.localFile === null ? (
          <img src={placeholderImage()} alt="placeholder image" />
        ) : (
          <Img
            fluid={featured_media.localFile.childImageSharp.fluid}
            className="backimage"
          />
        )}
      </div>
      <div className="container__content">
        <hr />
        <h3 className="title">{trimString(title, 50)}</h3>
        {/* <p className="excerpt">{trimString(excerpt, 180)}</p> */}
        <div
          className="excerpt"
          dangerouslySetInnerHTML={{ __html: trimString(excerpt, 160) }}
        />
        <button>Read More...</button>
      </div>
    </StyledBlogCardImageBack>
  )
}

BlogCardImageBack.defaultProps = {
  title: "How to Do a Competitive Analysis in Digital Marketing",
  excerpt: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt neque explicabo quis nobis rerum alias id? Molestiae, illum. Temporibus architecto impedit reiciendis numquam nostrum et.`,
  slug: `slug`,
}

export default BlogCardImageBack
