import React, { useState } from "react"

function useDisclosure() {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  const onToggle = () => setIsOpen(!isOpen);

  return { isOpen, onOpen, onClose, onToggle }
}

export default useDisclosure;


// const HomePage = () => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   return (
//     <button onClick={onOpen}>Open Modal</button>
//     <Modal
//       isOpen={isOpen}
//       onClose={onClose}
//     >
//       some stuff
//     </Modal>
//   )
// }