/**
 * @desc Trims a string and returns it with along with adding a '...' at the end
 * @param {string} string - the string to be trimmed
 * @param {integer} length - the length by which you want to trim the string (default: 20)
 * @returns {string} - the trimmed string
 */

export const trimString = (string, length=20) => {
  // Checks if string length > length to be trimmed
  if(string.length > length) {
    return `${string.substring(0, length)}...`;
  } else {
    return string;
  }
}