import React from 'react'
import Layout from "../components/resuable/layout/layout";
import Section from '../components/resuable/section/section';
import HomeHeroNoImage from '../components/page/home-components/home-hero/variants/home-hero-no-image';
import HomeClientsSection from '../components/page/home-components/home-clients-section/home-clients-section';
import TabsHighlight from '../components/page/home-components/home-features-section/variants/tabs-highlight';
import { FeatureSection } from '../components/page/home-components/home-features-section';
import { EpicSection } from '../components/page/home-components/home-epic-section';
import { round } from 'roundrrr'
import BlogCardImageBack from '../components/resuable/blog/blog-card/blog-card-image-back';
import SeoReportForm from '../components/page/seo-report-components/seo-report-header/seo-report-form/seo-report-form';
import SeoReportHeader from '../components/page/seo-report-components/seo-report-header/seo-report-header';

const Help = () => {
  return (
    <Layout
      theme="light"
    >
      <SeoReportHeader />
    </Layout>
  )
}

export default Help
