import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import styled from "styled-components"

const StyledModal = styled(Modal)`
  border: 1px solid red;
  .main-video {
    border: 1px solid red;
  }
`

const Popup = ({ children, className, isOpen, onOpen, onClose, style }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      portalClassName={`portal-${className}`}
      className={`main-${className}`}
      overlayClassName={`overlay-${className}`}
      style={style}
    >
      {children}
    </StyledModal>
  )
}

Popup.defaultProps = {
  className: "generic",
}

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Popup
