import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useTransition, animated } from "react-spring"

import { dimensions, colors, fonts } from "../../../../../utils/styles/styles"
import { ButtonType } from "../../../../resuable/button"
import useInterval from "../../../../hooks/useInterval"

import Back from "../../../../../images/patterns/flaticon/seodark1.jpg"
import { getSourceLink } from "../../../../../utils/tracker/links"
import { Link } from "gatsby"

const StyledHomeHeroNoImage = styled.header`
  box-sizing: border-box;
  width: 100%;
  /* max-width: ${dimensions.siteWidth}; */
  /* height: 80vh; */
  margin: 0 auto;
  padding: 240px 0px 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(${Back}) repeat;
  background-attachment: fixed;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    span.highlight {
      position: absolute;
      top: -130px;
      color: ${colors.pri};
      text-shadow: 0px 0px 20px ${colors.priGlow};
      font-family: ${fonts.pri};
      font-weight: 700;
      font-size: 136px;
      line-height: 100%;
    }
  }
  div.container__animated-text {
    position: relative;
  }
  div.container__buttons {
    position: relative;
    top: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    div.container__buttons-text {
      width: 100%;
      margin-bottom: 20px;
      h1 {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
      }
    }
    
    p.nocc {
      width: 100%;
      padding-top: 20px;
      font-size: 10px;
      color: ${colors.gray};
      text-align: center;
    }
    a:not(:last-child) {
      margin-right: 20px;
    }
  }
  @media (max-width: 768px) {
    > div {
      span.highlight {
        top: -60px;
        font-size: 64px;
      }
    }
  }
  @media (max-width: 480px) {
    > div {
      span.highlight {
        top: -30px;
        font-size: 48px;
      }
    }
    div.container__buttons {
      div.container__buttons-text {
        h1 {
          font-size: 18px;
        }
      }
      flex-direction: column;
      align-items: center;
      a:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
`

const DynamicText = ["Simplified", "Strategic", "Sensitive"]

const HomeHeroNoImage = props => {
  const [textIndex, changeTextIndex] = useState(0)
  useInterval(() => {
    // Your custom logic here
    if (textIndex === 2) {
      changeTextIndex(0)
    } else {
      changeTextIndex(textIndex + 1)
    }
  }, 4000)
  const transitons = useTransition(textIndex, null, {
    config: { duration: 1200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  return (
    <StyledHomeHeroNoImage>
      <div className="container__animated-text">
        {transitons.map(({ item, props, key }) => {
          return (
            <animated.span key={key} style={props} className="highlight">
              {DynamicText[item]}
            </animated.span>
          )
        })}
      </div>

      <div className="container__buttons">
        <div className="container__buttons-text">
          <h1>Turn Your Marketing Ideas into Website Traffic.</h1>
        </div>
        <a
          href={`https://app.leadmirror.com/#/signup/wizard/1?initLandingPage=${getSourceLink()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonType.Base variant="primary" id="home_hero-no-image">
            Try for Free!
          </ButtonType.Base>
        </a>
        {/* <a href="https://app.leadmirror.com/#/?sandbox=true" target="_blank" rel="noopener noreferrer">
          <ButtonType.Ghost className="demo" id="home__epic-expdemo">Live Demo</ButtonType.Ghost>
        </a> */}
        <Link to="/demo">
          <ButtonType.Base variant="plain" className="demo" id="home__epic-expdemo">
            Get personalised Demo
          </ButtonType.Base>
        </Link>
        <p className="nocc">* No Credit Card Needed</p>
      </div>
    </StyledHomeHeroNoImage>
  )
}

HomeHeroNoImage.defaultProps = {}

export default HomeHeroNoImage
